import React from "react";

import Link from "@/utils/link";
import { LinkButton, LinkText } from "../ui/Button";
import { useAuth } from "@/lib/auth";
import { host } from "@/utils/config";

import { CheckCircleIcon } from "@heroicons/react/outline";

import PurpleDoorLogoRect from "../Logo/PurpleDoorLogoRect";

const HomeBetterWay = () => {
    const auth = useAuth();
    const userId = auth.user ? auth.user.uid : "";

    return (
        <div className="w-full mb-8 sm:h-[640px]">
            <div className="relative w-full text-black">
                <h2 className="mt-8 sm:mt-16 text-2xl sm:text-4xl font-headline font-bold text-primary-500 text-center">
                    A Better Way to Connect Seniors with Communities
                </h2>

                <p className="mt-4 text-xl px-8 lg:px-48 sm:text-center font-bold">
                    Purple Door Finders offers a new platform for seniors and their families
                    to make better and more informed decisions about senior living and care
                    options.
                </p>

                <div className="absolute -top-16 -left-32 opacity-20">
                    <PurpleDoorLogoRect />
                </div>

                <div className="absolute top-[400px] right-0 opacity-20">
                    <PurpleDoorLogoRect />
                </div>
            </div>

            <div className="relative mt-8 px-4 sm:flex sm:justify-center sm:space-x-16">
                <div>
                    <Card
                        title="For Residents"
                        getStartedUrl={userId ? "/get-started" : "/create-account"}
                        learnMoreUrl="/how-it-works"
                        list={[
                            "Know your options",
                            "Take control of the search process",
                            "No high-pressure salespeople or “advisors”",
                            "Find the Right Fit&trade; community based on your individual needs",
                        ]}
                    />

                    {userId === "" ? (
                        <div className="mt-4">
                            Already have an account?{" "}
                            <Link href="/create-account">
                                <a className="text-primary-500 hover:underline">
                                    Login here.
                                </a>
                            </Link>
                        </div>
                    ) : null}
                </div>

                <div>
                    <Card
                        title="For Communities"
                        getStartedUrl={host + "community-portal"}
                        learnMoreUrl="/community-how-it-works"
                        list={[
                            "Better informed seniors and families",
                            "No sales commissions",
                            "Tools and insights for finding Right Fit&trade; residents",
                            "Direct connection with seniors",
                        ]}
                    />
                    <div className="mt-4">
                        Already listed?{" "}
                        <Link href={host + "community-portal"}>
                            <a className="text-primary-500 hover:underline">
                                Manage community here.
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * We use dangerouslySetInnerHTML to render the trade symbol &trade;
 * @param {*} param0
 * @returns
 */
const Card = ({ title, list, getStartedUrl, learnMoreUrl }) => {
    return (
        <div className="z-50 bg-gray-50 mt-8 sm:w-[440px] h-80 border-2 rounded shadow-xl overflow-hidden">
            <h2 className="w-full py-2 text-primary-500 text-xl sm:text-2xl font-headline font-bold text-center">
                {title}
            </h2>

            <div className="mt-2 px-8 h-40">
                <ul>
                    {list.map((item) => (
                        <li key={item}>
                            <div className="flex justify-start items-start space-x-2 space-y-0">
                                <div className="mb-2">
                                    <CheckCircleIcon className="w-6 h-6 text-primary-500" />
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: item }} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="mt-6 px-8 flex justify-start">
                <LinkButton href={getStartedUrl} className="px-4">
                    Get Started
                </LinkButton>

                {/*<LinkText href={learnMoreUrl} className="px-4">
                    Learn More
                </LinkText>*/}
            </div>
        </div>
    );
};

export default HomeBetterWay;
