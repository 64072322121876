import React, { useState } from "react";
import { useRouter } from "next/router";
import Link from "@/utils/link";
import Image from "next/legacy/image";

import HomeHeader, { links } from "./HomeHeader";
import HomeSearch from "./HomeSearch";
//import { useWindowSize } from "@/utils/hooks";
import { imageLoader } from "@/utils/image";

export const LinkItem = ({ link, label, path }) => {
    return (
        <div
            className={
                "px-2 py-1 text-primary-500 uppercase hover:bg-primary-100 hover:rounded" +
                (path === link ? " border-b-2 border-primary-500" : "")
            }
        >
            <Link href={link}>{label}</Link>
        </div>
    );
};

const HeaderAndSearch = () => {
    const router = useRouter();
    //const { width, height } = useWindowSize();

    const path = router.pathname;

    return (
        <div className="w-full">
            <HomeHeader fixed={false} />

            <div className="hidden sm:block sm:relative w-full h-[300px] bg-black">
                <Image
                    loader={imageLoader}
                    src="https://cdn.purpledoorfinders.com/purpledoorfinders/start-living-better"
                    alt="purple door senior living"
                    layout="fill"
                    objectFit="cover"
                    objectPosition="0px -0px"
                    priority
                    unoptimized={false}
                    className="opacity-80"
                />

                <div className="relative container mx-auto mb-16 pt-12 px-4 sm:px-16">
                    <h1 className="text-white text-5xl font-bold tracking-normal font-headline uppercase">
                        Discover Your Right Fit&trade;
                    </h1>
                    <h2 className="mt-4 text-white text-2xl font-semibold tracking-normal">
                        Start your search for senior and assisted living
                    </h2>

                    {/*<div className="z-50 absolute top-0 left-0 px-1 py-1 text-sm rounded bg-yellow-200">
                        <span>{width}</span>
                        <span>x</span>
                        <span>{height}</span>
                    </div>*/}

                    {/*<div className="mt-8 flex gap-6 text-black text-xl font-medium">
                        {links.map((a) => (
                            <LinkItem
                                key={a.label}
                                path={path}
                                link={a.link}
                                label={a.label}
                            />
                        ))}
                        </div>*/}
                </div>

                <div className="sm:container sm:mx-auto px-4 sm:px-16">
                    <HomeSearch />
                </div>
            </div>

            <div className="relative block w-full h-[300px] sm:hidden bg-black">
                <div className="">
                    <Image
                        loader={imageLoader}
                        src="https://cdn.purpledoorfinders.com/purpledoorfinders/start-living-better"
                        alt="purple door senior living"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="0px 0px"
                        priority
                        unoptimized={false}
                        className="opacity-80"
                    />
                </div>

                <div className="relative px-4 pt-16">
                    <h1 className="font-bold text-4xl text-white">Start Living Better</h1>
                    <h2 className="mt-4 text-white text-xl">
                        Find your Right Fit&trade; for senior and assisted living
                    </h2>
                </div>

                {/*<div className="z-50 absolute top-0 right-0 px-1 py-1 text-sm rounded bg-yellow-200">
                    <span>{width}</span>
                    <span>x</span>
                    <span>{height}</span>
                </div>*/}

                <div className="sm:container sm:mx-auto mt-8 px-4 sm:px-16">
                    <HomeSearch />
                </div>
            </div>
        </div>
    );
};

export default HeaderAndSearch;
