import React from "react";
import Image from "next/legacy/image";

import { Heading, SubHeading } from "../ui/Heading";
import { LinkButton } from "../ui/Button";
import { imageLoader } from "@/utils/image";
import { useAuth } from "@/lib/auth";

const HomeCreateAccount = () => {
    const auth = useAuth();

    const userId = auth.user ? auth.user.uid : "";

    return (
        <div className="flex flex-col-reverse sm:flex-row items-center">
            <div className="sm:w-1/2 px-4 sm:px-16 py-8">
                <Heading>{userId ? "Get Started" : "Create an Account"}</Heading>

                {userId ? (
                    <SubHeading className="mt-2">
                        Begin your search with our tools and resources so you can find your
                        Right Fit&trade;
                    </SubHeading>
                ) : (
                    <SubHeading className="mt-2">
                        Begin your search for senior living with a FREE account and unlock
                        all of the tools to manage your search.
                    </SubHeading>
                )}

                <div className="mt-8">
                    <LinkButton href={userId ? "/get-started" : "/create-account"}>
                        Get Started
                    </LinkButton>
                </div>
            </div>

            <div className="relative w-full sm:w-1/2 h-72">
                <Image
                    loader={imageLoader}
                    src="https://cdn.purpledoorfinders.com/purpledoorfinders/create-an-account.sm.jpg"
                    alt="register-your-community"
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                />
            </div>
        </div>
    );
};

export default HomeCreateAccount;
