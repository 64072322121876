import React, { useState, useEffect, useReducer } from "react";

import Link from "@/utils/link";

import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from "@heroicons/react/outline";

import { LinkButton, IconButton } from "../ui/Button";
import { getMostRecentSearchCity } from "../local/localStorage";
import { useWindowSize } from "@/utils/hooks";

import CommunityCard, { CommunityCardSkeleton, getAvailability } from "./CommunityCard";
import Button from "@/ui/Button";

const LEFT = "LEFT";
const RIGHT = "RIGHT";
const START = "START";
const END = "END";
const SET_SEARCH = "SET_SEARCH";
const SET_COMMUNITIES = "SET_COMMUNITIES";
const SET_INDEX = "SET_INDEX";
const SET_WIDTH = "SET_WIDTH";

const reducer = (state, action) => {
    switch (action.type) {
        case SET_SEARCH:
            return { ...state, search: action.search };

        case SET_COMMUNITIES:
            return { ...state, communities: action.communities };

        case SET_WIDTH:
            return {
                ...state,
                cardsPerLine: action.width < 640 ? 1 : action.width < 1280 ? 2 : 3,
            };

        case LEFT: {
            let index = state.index - state.cardsPerLine;
            if (index < 0) {
                index = 0;
            }

            return { ...state, index };
        }

        case RIGHT: {
            let index = state.index + state.cardsPerLine;
            if (index > state.communities.length - state.cardsPerLine) {
                index = state.communities.length - state.cardsPerLine;
            }

            if (index > state.communities.length) {
                index = state.communities.length - 1;
            }

            return { ...state, index };
        }

        case START:
            return { ...state, index: 0 };

        case END:
            return { ...state, index: state.communities.length - state.cardsPerLine };

        case SET_INDEX: {
            let index = action.index;
            if (index < 0) {
                index = 0;
            } else if (index > state.communities.length - state.cardsPerLine) {
                index = state.communities.length - state.cardsPerLine;
            }
            return { ...state, index };
        }

        default:
            return state;
    }
};

const isSelected = (i, index, cardsPerLine) => {
    return Math.floor(index / cardsPerLine) === i;
};

const HomeCarousel = () => {
    const { width } = useWindowSize();
    const [state, dispatch] = useReducer(reducer, {
        index: 0,
        communities: [],
        search: "",
        cardsPerLine: 3,
    });

    let data = [];

    useEffect(() => {
        const fetchData = async (search) => {
            const res = await fetch(`/api/community/city/${search}`);

            let communities = await res.json();

            // sort communities by paid plan and availability
            communities = communities.sort((a, b) => {
                const paidPlanA = a.plan && a.plan.startsWith("Paid");
                const paidPlanB = b.plan && b.plan.startsWith("Paid");

                if (paidPlanA && !paidPlanB) {
                    return -1;
                } else if (!paidPlanA && paidPlanB) {
                    return 1;
                } else if (paidPlanA && paidPlanB) {
                    const aa = getAvailability(a);
                    const bb = getAvailability(b);

                    if (aa !== "Not Available" && bb === "Not Available") {
                        return -1;
                    } else if (aa === "Not Available" && bb !== "Not Available") {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            });

            //console.log("res", communities);

            dispatch({ type: SET_COMMUNITIES, communities });
        };

        let search = getMostRecentSearchCity();
        if (search) {
            search = search.replaceAll(", ", "-");
        } else {
            //search = "Los-Angeles-CA";
            search = "";
        }

        dispatch({ type: SET_SEARCH, search });

        console.log("search", search);

        if (search && search.length > 0) {
            fetchData(search);
        }
    }, []);

    useEffect(() => {
        dispatch({ type: SET_WIDTH, width });
    }, [width < 1280]);

    const handleBullet = (i) => {
        dispatch({ type: SET_INDEX, index: state.cardsPerLine * i });
    };

    if (state.communities.length > 0) {
        const N = state.communities.length;

        if (width < 640) {
            data = [state.communities[state.index]];
        } else if (width < 1280 || N < 3) {
            if (N === 1) {
                data = [state.communities[state.index]];
            } else if (state.index <= 1) {
                data = [state.communities[0], state.communities[1]];
            } else if (state.index >= N - 2) {
                data = [state.communities[N - 2], state.communities[N - 1]];
            } else {
                data = [state.communities[state.index], state.communities[state.index + 1]];
            }
        } else {
            if (state.index <= 2) {
                data = [state.communities[0], state.communities[1], state.communities[2]];
            } else if (state.index >= N - 3) {
                data = [
                    state.communities[N - 3],
                    state.communities[N - 2],
                    state.communities[N - 1],
                ];
            } else {
                data = [
                    state.communities[state.index],
                    state.communities[state.index + 1],
                    state.communities[state.index + 2],
                ];
            }
        }
    }

    console.log("data", data);

    if (state.search === "") {
        return <div className="mt-16"></div>;
    }

    return (
        <div className="pt-8 pb-16">
            <div className="relative">
                {/*state.communities.length > 3 ? (
                    <IconButton
                        onClick={() => dispatch({ type: LEFT })}
                        className="absolute top-40 -left-16 h-12"
                    >
                        <ChevronLeftIcon className="w-8 h-8" />
                    </IconButton>
                ) : null*/}

                <div className="flex justify-center items-center space-x-4">
                    {data.length > 0
                        ? data.map((community, index) => (
                              <div
                                  key={community.nameId}
                                  className="bg-gray-50 hover:bg-gray-100 hover:shadow-xl rounded"
                                  style={{
                                      width: 100 / state.cardsPerLine + "%",
                                  }}
                              >
                                  <CommunityCard community={community} index={index} />
                              </div>
                          ))
                        : Array(state.cardsPerLine)
                              .fill("empty")
                              .map((_, index) => (
                                  <div
                                      key={index}
                                      className="bg-gray-50 hover:bg-gray-100 hover:shadow-xl"
                                      style={{
                                          width: 100 / state.cardsPerLine + "%",
                                      }}
                                  >
                                      <CommunityCardSkeleton index={index} />
                                  </div>
                              ))}
                </div>

                <div className="mt-8 flex justify-center items-center ">
                    <Button size="xl" href={"/" + state.search}>
                        View More Communities
                    </Button>
                </div>

                {/*state.communities.length > 3 ? (
                    <IconButton
                        onClick={() => dispatch({ type: RIGHT })}
                        className="absolute top-40 -right-16 h-12"
                    >
                        <ChevronRightIcon className="w-8 h-8" />
                    </IconButton>
                ) : null*/}
            </div>

            {/*<div className="mt-8 flex justify-center items-center">
                <IconButton onClick={() => dispatch({ type: START })} className="h-12">
                    <ChevronDoubleLeftIcon className="w-8 h-8" />
                </IconButton>

                <IconButton onClick={() => dispatch({ type: LEFT })} className="h-12 mx-8">
                    <ChevronLeftIcon className="w-8 h-8" />
                </IconButton>

                <div className="">
                    {state.index + state.cardsPerLine} / {state.communities.length}
                </div>

                <IconButton onClick={() => dispatch({ type: RIGHT })} className="h-12 mx-8">
                    <ChevronRightIcon className="w-8 h-8" />
                </IconButton>

                <IconButton onClick={() => dispatch({ type: END })} className="h-12">
                    <ChevronDoubleRightIcon className="w-8 h-8" />
                </IconButton>
            </div>*/}
        </div>
    );
};

export default HomeCarousel;
