import React, { useState } from "react";
import Link from "@/utils/link";
import Image from "next/legacy/image";

import { Heading } from "../ui/Heading";
import { imageLoader } from "@/utils/image";

import SearchDialog from "./SearchDialog";

import GetStartedSearch from "../Logo/GetStartedSearch";
import GetStartedLearn from "../Logo/GetStartedLearn";
import GetStartedExplore from "../Logo/GetStartedExplore";

const HowTheEasyWay = () => {
    const handleStartSearchNow = () => {
        //document.body.scrollTop = 0; // For Safari
        //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        document.body.scroll({
            top: 0,
            behavior: "smooth",
        });

        document.documentElement.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="py-8 w-full bg-primary-500">
            {/*<Heading className="px-4 py-8 sm:px-16 text-primary-50 text-center">
                The Easy Way to Find Senior Living
            </Heading>*/}

            <div className="mt-4 mx-auto px-8 sm:px-16 pb-16 max-w-4xl text-white space-y-16 sm:space-y-8">
                <div className="flex space-x-8 items-start">
                    <div>
                        <GetStartedExplore
                            width={64}
                            height={64}
                            style={{ stroke: "white", fill: "white", strokeWidth: 2 }}
                        />
                    </div>

                    <div>
                        <h3 className="mt-4 sm:mt-0 text-xl font-black font-headline">
                            Search On Your Terms
                        </h3>
                        <p className="mt-8 -ml-24 sm:mt-0 sm:ml-0 text-lg">
                            Open the doors to your senior living of choice directly without
                            sales advisors. You determine your best senior living option
                            with confidence.{" "}
                            <Link href="/how-it-works">
                                <a className="ml-1 font-black hover:underline">
                                    Learn more.
                                </a>
                            </Link>
                        </p>
                    </div>
                </div>

                <div className="flex space-x-8 items-start">
                    <div>
                        <GetStartedSearch
                            width={64}
                            height={64}
                            style={{ fill: "white" }}
                        />
                    </div>

                    <div>
                        <h3 className="mt-4 sm:mt-0 text-xl font-black font-headline">
                            FREE Search Tools
                        </h3>
                        <p className="mt-8 -ml-24 sm:mt-0 sm:ml-0 text-lg">
                            Purple Door Finders offers free, unrestricted access to the most
                            comprehensive listing of senior and assisted living options
                            available online.{" "}
                            <button
                                className="ml-1 font-black hover:underline"
                                onClick={handleStartSearchNow}
                            >
                                Start Search Now.
                            </button>
                        </p>
                    </div>
                </div>

                <div className="flex space-x-8 items-start">
                    <div>
                        <GetStartedLearn width={64} height={64} style={{ fill: "white" }} />
                    </div>

                    <div>
                        <h3 className="mt-4 sm:mt-0 text-xl font-black font-headline">
                            Right Fit&trade; Resources
                        </h3>
                        <p className="mt-8 -ml-24 sm:mt-0 sm:ml-0 text-lg">
                            Purple Door Finders has information and useful resources to help
                            you learn about senior and assisted living and support the
                            process of finding your Right Fit&trade;.{" "}
                            <Link href="/resources">
                                <a className="ml-1 font-black hover:underline">
                                    See All Resources.
                                </a>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            {/*<SearchDialog open={open} onClose={() => setOpen(false)} />*/}
        </div>
    );
};

export default HowTheEasyWay;
