import React, { useState } from "react";
import Link from "@/utils/link";
import Image from "next/legacy/image";

import { Heading } from "../ui/Heading";
import { imageLoader } from "@/utils/image";

import SearchDialog from "./SearchDialog";

const Item = ({
    title,
    text,
    imageSrc,
    buttonLabel,
    href,
    bg,
    onClick,
    position = "center",
}) => {
    return (
        <div className={"z-10 px-4 sm:px-4 py-8 sm:py-8 " + bg}>
            <h2 className="text-white text-center text-xl font-bold uppercase">{title}</h2>

            <div className="mt-8 h-72 xl:h-[330px]">
                <div className="flex justify-center">
                    {/*<img
                        src={`https://cdn.purpledoorfinders.com/Front/${10 + index}.jpg`}
                        alt=""
                        className="w-48 h-48 rounded-full object-cover"
                    />*/}

                    <div className="relative w-48 h-48 rounded-full overflow-hidden">
                        <Image
                            loader={imageLoader}
                            src={imageSrc}
                            alt={title}
                            layout="fill"
                            objectFit="cover"
                            objectPosition={position}
                        />
                    </div>
                </div>
                <div className="px-6">
                    <p className="mt-8 text-lg text-primary-50 text-center">{text}</p>
                </div>
            </div>

            <div className="mt-16 mb-16 xl:mb-4 sm:mt-24 2xl:mt-16 flex justify-center">
                {onClick ? (
                    <button
                        className="px-4 py-1 text-center font-bold tracking-wide rounded shadow text-primary-500 bg-gray-light hover:bg-gray-med hover:text-gray-50"
                        onClick={onClick}
                    >
                        {buttonLabel}
                    </button>
                ) : (
                    <Link href={href}>
                        <a
                            className={
                                "px-4 py-1 text-center font-bold tracking-wide rounded shadow text-primary-500 bg-gray-light hover:bg-gray-med hover:text-gray-50"
                            }
                        >
                            {buttonLabel}
                        </a>
                    </Link>
                )}
            </div>
        </div>
    );
};

const works = [
    {
        title: "Connect",
        text: "Connect directly to communities without the hassle of advisors and subjective sales people. Take control of your decision.",
        buttonLabel: "Create Account",
        href: "/create-account",
        //bg: "bg-primary-800 sm:bg-primary-500",
        bg: "bg-primary-500",
        imageSrc:
            "https://cdn.purpledoorfinders.com/purpledoorfinders/how-it-works-connect.sm.jpg",
        position: "right",
    },
    {
        title: "Research",
        text: "Use our platform and tools to search for senior living options that meet your needs. Manage the process through your FREE account.",
        buttonLabel: "Start Search",
        href: "/favorites",
        //bg: "bg-primary-500 sm:bg-primary-800 xl:bg-primary-500",
        bg: "bg-primary-500",
        imageSrc:
            "https://cdn.purpledoorfinders.com/purpledoorfinders/how-it-works-research.sm.jpg",
        position: "right",
    },
    {
        title: "Learn",
        text: "Seniors and their families can learn about senior living options with our series of tools to start their search.",
        buttonLabel: "Resources",
        href: "/resources",
        //bg: "bg-primary-800 xl:bg-primary-500",
        bg: "bg-primary-500",
        imageSrc:
            "https://cdn.purpledoorfinders.com/purpledoorfinders/how-it-works-learn.sm.jpg",
        position: "center",
    },
];

const HowToUse = () => {
    {
        /*const [open, setOpen] = useState(false);

    const handleSearch = () => {
        setOpen(true);
    };*/
    }

    return (
        <div className="w-full bg-primary-500">
            <Heading className="px-4 py-8 sm:px-16 text-primary-50 text-center">
                How to Use Purple Door Finders
            </Heading>

            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-16 sm:gap-x-0 ">
                {works.map((work, index) => (
                    <Item
                        key={work.title}
                        {...work}
                        index={index}
                        onClick={
                            false && work.title === "Research" ? handleSearch : undefined
                        }
                    />
                ))}
            </div>

            {/*<SearchDialog open={open} onClose={() => setOpen(false)} />*/}
        </div>
    );
};

export default HowToUse;
