import React from "react";

const NotFound = () => {
    return (
        <div>
            <div className="w-full bg-primary-500 p-16">
                <h1 className="text-4xl text-primary-50 font-headline font-bold">
                    Page Not Found
                </h1>
            </div>
            <div className="w-full mt-8 px-16 text-lg">
                <h3 className="text-lg">Available Links:</h3>

                <div className="mt-2 flex">
                    <span className="w-48">Purple Door Finders:</span>
                    <a
                        href="https://purpledoorfinders.com/"
                        className="text-primary-500 hover:underline"
                    >
                        https://purpledoorfinders.com
                    </a>
                </div>

                <div className="flex">
                    <div className="w-48">For Communities:</div>
                    <a
                        href="https://purpledoorfinders.com/"
                        className="text-primary-500 hover:underline"
                    >
                        https://portal.purpledoorfinders.com/community-portal
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
