import React from "react";
import styles from "./GetStarted.module.css";

const GetStartedExplore = ({ width = 100, height = 100, style = {} }) => {
    return (
        <svg viewBox="0 0 100 100" width={width} height={height}>
            <path
                className={styles.cls1}
                style={style}
                d="M49.61,99.44A49.3,49.3,0,1,1,98.9,50.14,49.35,49.35,0,0,1,49.61,99.44Zm0-95.56A46.26,46.26,0,1,0,95.86,50.14,46.31,46.31,0,0,0,49.61,3.88Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M53.09,69.39H31.65a3.94,3.94,0,0,1-3.93-3.93V25.13a3.94,3.94,0,0,1,3.93-3.93H53.09A3.94,3.94,0,0,1,57,25.13v4.92a.79.79,0,0,1-1.58,0V25.13a2.35,2.35,0,0,0-2.35-2.35H31.65a2.35,2.35,0,0,0-2.35,2.35V65.46a2.35,2.35,0,0,0,2.35,2.35H53.09a2.35,2.35,0,0,0,2.35-2.35.79.79,0,0,1,1.58,0A3.94,3.94,0,0,1,53.09,69.39Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M53.09,70.14H31.65A4.69,4.69,0,0,1,27,65.46V25.13a4.7,4.7,0,0,1,4.69-4.69H53.09a4.7,4.7,0,0,1,4.69,4.69v4.92a1.55,1.55,0,0,1-3.1,0V25.13a1.59,1.59,0,0,0-1.59-1.59H31.65a1.59,1.59,0,0,0-1.59,1.59V65.46a1.59,1.59,0,0,0,1.59,1.59H53.09a1.59,1.59,0,0,0,1.59-1.59,1.55,1.55,0,0,1,3.1,0A4.69,4.69,0,0,1,53.09,70.14ZM31.65,22a3.18,3.18,0,0,0-3.17,3.17V65.46a3.17,3.17,0,0,0,3.17,3.17H53.09a3.17,3.17,0,0,0,3.17-3.17,3.21,3.21,0,0,1-3.17,3.11H31.65a3.11,3.11,0,0,1-3.11-3.11V25.13A3.11,3.11,0,0,1,31.65,22H53.09a3.11,3.11,0,0,1,3.11,3.11v4.92l.06-4.92A3.18,3.18,0,0,0,53.09,22Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M56.23,62.84a.79.79,0,0,1-.79-.79V34.44a.79.79,0,1,1,1.58,0V62.05A.79.79,0,0,1,56.23,62.84Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M56.23,63.6a1.56,1.56,0,0,1-1.55-1.55V34.44a1.55,1.55,0,0,1,3.1,0V62.05A1.56,1.56,0,0,1,56.23,63.6Zm0-29.16L56.2,62.05l0,0a0,0,0,0,0,0,0Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M56.23,26H28.51a.79.79,0,1,1,0-1.58H56.23a.79.79,0,0,1,0,1.58Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M56.23,26.78H28.51a1.55,1.55,0,0,1,0-3.1H56.23a1.55,1.55,0,1,1,0,3.1ZM28.51,25.2l27.72.06a0,0,0,0,0,0,0Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M38,63.3H28.51a.79.79,0,0,1,0-1.58H38a.79.79,0,0,1,0,1.58Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M38,64.05H28.51a1.55,1.55,0,1,1,0-3.09H38a1.55,1.55,0,1,1,0,3.09Zm-9.51-1.57,9.51.06a0,0,0,0,0,0,0Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M43.81,66.38H40.93a.79.79,0,1,1,0-1.58h2.88a.79.79,0,1,1,0,1.58Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M43.81,67.14H40.93a1.55,1.55,0,0,1,0-3.1h2.88a1.55,1.55,0,0,1,0,3.1Zm-2.88-1.58,2.88.06a0,0,0,0,0,0,0Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M49,64.92A16.64,16.64,0,1,1,65.64,48.29,16.65,16.65,0,0,1,49,64.92Zm0-31.69A15.06,15.06,0,1,0,64.06,48.29,15.07,15.07,0,0,0,49,33.23Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M49,65.68A17.4,17.4,0,1,1,66.4,48.28,17.42,17.42,0,0,1,49,65.68Zm0-33.27A15.88,15.88,0,1,0,64.88,48.28,15.89,15.89,0,0,0,49,32.41ZM49,64.1A15.82,15.82,0,1,1,64.82,48.28,15.83,15.83,0,0,1,49,64.1ZM49,34A14.3,14.3,0,1,0,63.3,48.28,14.31,14.31,0,0,0,49,34Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M76.36,76.42a.78.78,0,0,1-.56-.23L59.66,60.05a.79.79,0,0,1,0-1.12.78.78,0,0,1,1.11,0L76.92,75.08a.79.79,0,0,1-.56,1.34Z"
            />
            <path
                className={styles.cls1}
                style={style}
                d="M76.36,77.18a1.58,1.58,0,0,1-1.1-.45L59.12,60.58a1.55,1.55,0,0,1,1.09-2.64h0a1.54,1.54,0,0,1,1.09.45L77.45,74.54a1.55,1.55,0,0,1-1.09,2.64ZM60.23,59.47,76.36,75.66h0Z"
            />
        </svg>
    );
};

export default GetStartedExplore;
